@tailwind components;

@layer components {
  .alert {
    @apply mb-5 p-3 w-3/4 z-50 absolute font-extrabold rounded duration-200 ease-in-out right-8 bg-opacity-90;
  }

  .alert h4 {
    margin-top: 0;
    color: inherit;
  }

  .alert .alert-link {
    font-weight: bold;
  }

  .alert>p,
  .alert>ul {
    margin-bottom: 0;
  }

  .alert>p+p {
    margin-top: 5px;
  }

  .alert-dismissable,
  .alert-dismissible {
    padding-right: 35px;
  }

  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }

  .color2Match {
    background-color: #dff0d8;
  }

  .alert-success {
    @apply text-green-700 border-2 border-green-700 bg-green-100;
  }

  .alert-success hr {
    border-top-color: #c9e2b3;
  }

  .alert-success .alert-link {
    color: #2b542c;
  }

  .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }

  .alert-info hr {
    border-top-color: #a6e1ec;
  }

  .alert-info .alert-link {
    color: #245269;
  }

  .alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }

  .alert-warning hr {
    border-top-color: #f7e1b5;
  }

  .alert-warning .alert-link {
    color: #66512c;
  }

  .alert-danger {
    @apply text-red-700 border border-red-800 bg-red-200;
  }

  .alert-danger hr {
    border-top-color: #e4b9c0;
  }

  .alert-danger .alert-link {
    color: #843534;
  }

  .close {
    float: right;
    margin-right: 2rem;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }

  button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
  }
}
