@tailwind components;

/* //NOTE: As much as possible, keep alphabetized. */
@layer components {
  .alert-warning {
    @apply mx-auto p-4 w-5/6 fixed text-red-700 border-4 border-red-700 border-solid bg-red-200 flex items-center;
  }

  .banner {
    @apply m-4 p-4 bg-gray-700 bg-opacity-50 rounded;
  }

  .banner-success {
    @apply text-green-700 border-green-800 bg-green-200 border-1;
  }

  .banner-error {
    @apply text-red-700 border-red-800 bg-red-300 border-1;
  }

  .bg-stars {
    background-image: url("/assets/_backgrounds/space4.gif");
    background-repeat: repeat;
  }

  .bg-wallpaper {
    @apply h-full bg-fixed bg-right-top bg-cover;
  }

  .border-1 {
    @apply border border-white border-solid;
  }

  .borderCenter {
    @apply p-2 text-center align-middle border border-white border-solid;
  }

  .borderLeft {
    @apply p-2 text-left align-middle border border-white border-solid;
  }

  .borderRight {
    @apply p-2 text-right align-middle border border-white border-solid;
  }

  .btn {
    @apply mx-2 p-2 no-underline border-2 border-blue-500 border-solid bg-accent-blue-dk-500 text-1.25 rounded-md;
  }

  .btn:active:enabled {
    @apply text-yellow-300 border-2 border-blue-500 bg-accent-blue-dk-500;
  }

  .btn:focus {
    @apply text-yellow-300 border-2 border-blue-500 bg-accent-blue-dk-500;
  }

  .btn:hover:enabled {
    @apply text-white border-2 border-blue-500 bg-accent-blue-dk-700;
  }

  .btn-default:hover {
    @apply text-white border-2 border-blue-500 bg-accent-blue-dk-500;
  }

  .btn:disabled {
    @apply text-black border-2 border-gray-800 bg-opacity-45 bg-accent-blue-dk-500;
  }

  .btnNarrow {
    @apply text-yellow-300 no-underline border-2 border-blue-500 border-solid bg-accent-blue-dk-500 text-1.5;
  }

  .btnLeft {
    @apply bg-left bg-accent-blue-dk-200;
  }

  .btnTop {
    @apply bg-top bg-accent-blue-dk-200;
  }

  .code {
    @apply px-4 pt-4 pb-8 text-yellow-300 bg-gray-800 border-1;
  }

  .container {
    @apply w-full;
  }

  .col-container {
    @apply flex flex-col items-center justify-center;
  }

  .dataMiddle {
    @apply align-middle;
  }

  .data:nth-child(even) {
    @apply bg-gray-100 bg-opacity-20;
  }

  .dataLight {
    @apply bg-gray-100 bg-opacity-15;
  }

  .data-even-light:nth-child(even) {
    @apply bg-gray-100 bg-opacity-15;
  }

  .data-odd-light:nth-child(odd) {
    @apply bg-gray-100 bg-opacity-15;
  }

  .dataDark {
    @apply bg-black bg-opacity-15;
  }

  .data-even-dark:nth-child(even) {
    @apply bg-black bg-opacity-15;
  }

  .data-odd-dark:nth-child(odd) {
    @apply bg-black bg-opacity-20;
  }

  .amk {
    @apply bg-gray-100 bg-opacity-20;
  }

  .debug-screens {
    @apply text-0.875 2xl:text-1;
  }

  details {
    @apply cursor-pointer;
  }

  details[open] summary svg-component[right] {
    display: none;
  }

  details[open] summary svg-component[down] {
    display: inherit;
  }

  details:not([open]) summary svg-component[right] {
    display: inherit;
  }

  details:not([open]) summary svg-component[down] {
    display: none;
  }

  details>summary {
    list-style: none;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-2 {
    flex-grow: 2;
    flex-shrink: 2;
    flex-basis: 0%;
  }

  .field {
    @apply w-full;
  }

  .input-group {
    @apply w-full flex items-center;
  }

  .inputText {
    @apply w-full;
  }

  .inputNum {
    @apply w-full;
  }

  .ism-black {
    @apply text-gray-900;
  }

  .ism-black:focus {
    @apply text-gray-900;
  }

  .ism-black:visited {
    @apply text-gray-900;
  }

  .ism-black:active {
    @apply text-gray-900;
  }

  .ism-blue {
    @apply text-accent-blue-dk-500;
  }

  .ism-green {
    @apply text-accent-lime-500;
  }

  .ism-green:visited {
    @apply text-accent-lime-500;
  }

  .ism-green:focus {
    @apply text-accent-lime-500;
  }

  .ism-green:active {
    @apply text-accent-lime-500;
  }

  .ism-green:hover a {
    @apply underline font-semibold text-white;
  }

  .ism-gold {
    @apply text-yellow-300;
  }

  .ism-gold:hover a {
    @apply underline font-semibold text-white;
  }

  .ism-gold:active {
    @apply text-yellow-300;
  }

  .ism-gold:focus {
    @apply text-yellow-300;
  }

  .ism-gold:visited {
    @apply text-yellow-300;
  }

  .ism-gold hr {
    @apply border border-yellow-300 border-solid;
  }

  .ismNoStretch-textarea {
    @apply lg:items-center;
  }

  .ism-white {
    @apply text-white;
  }

  .ism-white:visited {
    @apply text-white;
  }

  .ism-white:focus {
    @apply text-white;
  }

  .ism-white:active {
    @apply text-white;
  }

  /* .mobile {
    @apply mt-24 px-8 h-auto top-0 z-20 absolute font-bold text-yellow-300 flex flex-col items-start justify-center shadow-xl navbarLeft8 rounded-b-xl;
  } */
  .moonImage {
    @apply m-2 w-12 rounded-full;
  }

  .moonImage img {
    @apply w-full rounded-full;
  }

  .moonInfo,
  .planetInfo {
    @apply mx-2 text-left text-white text-1 whitespace-nowrap;
  }

  .myGrid {
    width: 900px;
    height: 450px;
  }

  .ngActiveLinkGold {
    @apply ism-gold;
  }

  /* Remove the temporary patch, once this finally works! */
  /* See bottom of styles.css */
  /* Of course replace the 'red' with 'lime' */
  /* //ANCHOR[id=ngActiveLinkGreen] */
  /* //LINK - ../styles.css#ngActiveLinkGreen */
  .ngActiveLinkGreen {
    @apply font-bold text-accent-lime-400;
  }

  .ngActiveLinkWhite {
    @apply text-white;
  }

  .planetImage {
    @apply m-2 w-20 rounded-full;
  }

  .planetImage img {
    @apply w-full;
  }

  .planetImageSmall {
    @apply w-10;
  }

  .planetImageSmall img {
    @apply w-10;
  }

  .select {
    @apply w-full;
  }

  .spaghettiOnWall {
    @apply z-10 sticky;
  }

  .spaghettiOnTable {
    @apply top-0 z-10 sticky border border-separate bg-gray-100 bg-opacity-20;
  }

  .spaghettiOnTable-light {
    @apply top-0 z-10 sticky border border-separate bg-gray-100 bg-opacity-85;
  }

  .spaghettiOnTable-dark {
    @apply top-0 z-10 sticky border border-separate bg-gray-800 bg-opacity-85;
  }

  .starImage {
    @apply w-full rounded-full;
  }

  .starImage img {
    @apply w-20;
  }

  table {
    @apply text-1.25 relative break-normal border-collapse table-auto border;
  }

  /* thead {
    @apply top-0 bg-black bg-opacity-60;
  } */
  th {
    @apply p-2 font-bold whitespace-normal border;
  }

  td {
    @apply p-2 break-all whitespace-normal border;
  }

  .templateMainDiv {
    @apply mx-auto flex flex-col iBTTiny:w-11/12 md:w-7/8;
  }

  .versions {
    @apply border-t border-r text-1;
  }

  .z-55 {
    z-index: 55;
  }
}
