@tailwind components;

@layer components {
  .menuLeftSection {
    @apply p-2 font-bold text-yellow-300 flex text-1.125 whitespace-nowrap hover:text-white visited:text-white;
  }

  /* css focus works in tandem with routerLinkActive */
  .menuLeftItem {
    @apply iBTTiny:p-1 lanPixel2xl:px-4 text-white font-normal text-1.125 active:text-accent-lime-500 focus:text-accent-lime-500 hover:text-yellow-300 visited:text-white whitespace-nowrap;
  }

  .sub-menuLeftItem {
    @apply p-2 whitespace-nowrap text-white font-normal text-1.125 active:text-accent-lime-500 focus:text-accent-lime-500 hover:text-yellow-300 visited:text-white;
  }
}
