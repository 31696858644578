@tailwind components;

@layer components {

  input.ng-invalid,
  input.ism-invalid {
    /* @apply border-red-500; */
    border-width: 2px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity));
  }

  /* .example-option {
    position: relative;
    padding: 0px;
  } */
  .example-option[aria-selected='true'] {
    background: rgba(0, 0, 0, 0.2);
  }

  .example-option:focus {
    background: rgba(0, 0, 0, 0.1);
  }

  .option-bw[aria-selected='true'] {
    background: rgba(199, 199, 199, 0.7);
  }

  .option-bw:focus {
    background: rgba(203, 203, 203, 0.6);
  }
}
