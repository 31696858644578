/* @tailwind base; */
@tailwind components;

/* @layer base { */
/*
[type=text], input:where(:not([type])), [type=email], [type=url], [type=password], [type=number], [type=date], [type=datetime-local], [type=month], [type=search], [type=tel], [type=time], [type=week], [multiple], textarea, select {
  appearance: none;
  background-color: #fff;
  border-style: none;
  outline-style: none;;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
 */
/* .form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  border-style: none;
  outline-style: none;;
} */
/* } */
@layer components {
  .form-input {
    @apply px-2 py-0 border-none border-black text-1.25;
  }

  .form-select {
    @apply px-2 py-0 border-none border-black text-1.25;
  }

  .form-textarea {
    @apply p-2 border-none border-black text-1.25;
  }

  .form-multiselect {
    @apply px-2 py-0 border-none border-black text-1.25;
  }

  .form-checkbox:enabled {
    @apply px-2 py-0 w-8 h-8;
  }

  .form-checkbox:checked:hover:enabled {
    @apply px-2 py-0 w-8 h-8 text-yellow-600;
  }

  .form-checkbox:disabled {
    @apply px-2 py-0 w-8 h-8 border-2 border-gray-800 bg-gray-600 bg-opacity-45;
  }

  .form-checkbox:checked:hover:disabled {
    @apply px-2 py-0 w-8 h-8 border-2 border-gray-800 bg-gray-600 bg-opacity-45;
  }
}
