@tailwind components;

@layer components {
  .nav-tabs>li>a {
    @apply border-b-0 border-white bg-gray-200 border-1 bg-opacity-15;
  }

  .popover-content {
    @apply p-4 text-black;
  }

  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 4px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    line-break: auto;
  }

  .panel-body {
    @apply w-full text-white flex flex-col text-1.25;
  }

  .panel-default {
    @apply p-2 w-full text-1.5 flex ism-gold;
  }

  .panel-default>.panel-heading {
    @apply p-2 w-full font-bold flex items-center;
  }

  .panel-group {
    @apply w-full;
  }

  .panel-group .panel {
    @apply w-full;
  }

  .panel-heading {
    @apply font-bold border border-white;
  }

  .panel-title {
    @apply font-bold;
  }

  .panel-title a {
    @apply font-bold;
  }

  .tab-content {
    @apply px-4 w-full border border-white;
  }

  .uit-accordion {
    @apply -m-1 p-0;
  }
}
