@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
  .overlay-dark-5 {
    @apply bg-gray-900 bg-opacity-5;
  }

  .overlay-dark-10 {
    @apply bg-gray-900 bg-opacity-10;
  }

  .overlay-dark-15 {
    @apply bg-gray-900 bg-opacity-15;
  }

  .overlay-dark-20 {
    @apply bg-gray-900 bg-opacity-20;
  }

  .overlay-dark-25 {
    @apply bg-gray-900 bg-opacity-25;
  }

  .overlay-dark-30 {
    @apply bg-gray-900 bg-opacity-30;
  }

  .overlay-dark-35 {
    @apply bg-gray-900 bg-opacity-35;
  }

  .overlay-dark-40 {
    @apply bg-gray-900 bg-opacity-40;
  }

  .overlay-dark-45 {
    @apply bg-gray-900 bg-opacity-45;
  }

  .overlay-dark-50 {
    @apply bg-gray-900 bg-opacity-50;
  }

  .overlay-dark-55 {
    @apply bg-gray-900 bg-opacity-55;
  }

  .overlay-dark-60 {
    @apply bg-gray-900 bg-opacity-60;
  }

  .overlay-dark-65 {
    @apply bg-gray-900 bg-opacity-65;
  }

  .overlay-dark-70 {
    @apply bg-gray-900 bg-opacity-70;
  }

  .overlay-dark-75 {
    @apply bg-gray-900 bg-opacity-75;
  }

  .overlay-dark-80 {
    @apply bg-gray-900 bg-opacity-80;
  }

  .overlay-dark-85 {
    @apply bg-gray-900 bg-opacity-85;
  }

  .overlay-dark-90 {
    @apply bg-gray-900 bg-opacity-90;
  }

  .overlay-dark-95 {
    @apply bg-gray-900 bg-opacity-95;
  }

  a {
    @apply no-underline text-yellow-300;
  }

  a:visited {
    @apply text-yellow-300;
  }

  a:active {
    @apply text-yellow-300;
  }

  a:hover {
    @apply underline text-white;
  }

  a:disabled {
    @apply opacity-75;
  }

  .bg-wallpaper {
    @apply h-full bg-fixed bg-right-top bg-cover;
  }

  .bg-wallpaper-img {
    background-image: url("../assets/_backgrounds/bgPlanets2088_blk40.png");
  }

  body {
    @apply m-0 p-0 w-full h-full font-sans text-white text-1.25 debug-screens scrollbar-none 2xl:text-1.5 box-border;
  }

  .fixed-top {
    @apply top-0 z-30 fixed;
  }

  .fixed-bottom {
    @apply bottom-0 z-30 fixed;
  }

  .h-13 {
    height: 3.25rem;
  }

  .h-14 {
    height: 3.5rem;
  }

  .h-15 {
    height: 3.75rem;
  }

  hr {
    @apply my-4 w-full prose border-t-2 border-gray-700 border-solid;
  }

  html {
    @apply m-0 p-0 h-[calc(100%-56px)] box-border bg-black bg-wallpaper bg-wallpaper-img;
  }

  .inline {
    @apply p-4 w-full prose flex;
  }

  input input:focus input:active input:focus-within {
    @apply border-0 shadow-none ring-0
  }

  label {
    @apply text-right inline-block;
  }

  .left-76 {
    left: 19rem;
  }

  .left-1\/5 {
    left: 20%;
  }

  .left-2\/5 {
    left: 40%;
  }

  .left-3\/5 {
    left: 60%;
  }

  .left-4\/5 {
    left: 80%;
  }

  .left-1\/6 {
    left: 16.666%;
  }

  .left-5\/6 {
    left: 83.333%;
  }

  .left-1\/8 {
    left: 12.5%;
  }

  .left-3\/8 {
    left: 37.5%;
  }

  .left-5\/8 {
    left: 62.5%;
  }

  .left-7\/8 {
    left: 87.5%;
  }

  .left-1\/12 {
    left: 8.3%;
  }

  .left-5\/12 {
    left: 41.66%;
  }

  .left-7\/12 {
    left: 58.33%;
  }

  .left-9\/12 {
    left: 75%;
  }

  .left-11\/12 {
    left: 91.66%;
  }

  .m-28 {
    margin: 7rem;
  }

  .mb-28 {
    margin-bottom: 7rem;
  }

  .ml-28 {
    margin-left: 7rem;
  }

  .ml-1\/3 {
    margin-left: 33.33%;
  }

  .ml-1\/4 {
    margin-left: 25%;
  }

  .ml-3\/4 {
    margin-left: 75%;
  }

  .ml-1\/5 {
    margin-left: 20%;
  }

  .ml-2\/5 {
    margin-left: 40%;
  }

  .ml-3\/5 {
    margin-left: 60%;
  }

  .ml-4\/5 {
    margin-left: 80%;
  }

  .ml-1\/8 {
    margin-left: 12.5%;
  }

  .ml-3\/8 {
    margin-left: 37%;
  }

  .ml-5\/8 {
    margin-left: 62.5%;
  }

  .ml-7\/8 {
    margin-left: 87.5%;
  }

  .mt-13 {
    margin-top: 3.25rem;
  }

  .-mt-13 {
    margin-top: -3.25rem;
  }

  .p-14 {
    padding: 3.5rem;
  }

  .p-42 {
    padding: 11.25rem;
  }

  .p-50 {
    padding: 13rem;
  }

  .pb-14 {
    padding-bottom: 3.5rem;
  }

  .pb-42 {
    padding-bottom: 11.25rem;
  }

  .pb-50 {
    padding-bottom: 13rem;
  }

  .pl-14 {
    padding-left: 3.5rem;
  }

  .pl-42 {
    padding-left: 11.25rem;
  }

  .pl-50 {
    padding-left: 13rem;
  }

  .pr-14 {
    padding-right: 3.5rem;
  }

  .pr-42 {
    padding-right: 11.25rem;
  }

  .pr-50 {
    padding-right: 13rem;
  }

  .pt-14 {
    padding-top: 3.5rem;
  }

  .pt-50 {
    padding-top: 13rem;
  }

  .sticky-top {
    @apply top-0 z-30 sticky;
  }

  .sticky-bottom {
    @apply bottom-0 z-30 sticky;
  }

  .top-22 {
    top: 5.5rem;
  }

  .top-76 {
    top: 19rem;
  }

  .top-1\/5 {
    top: 20%;
  }

  .top-2\/5 {
    top: 40%;
  }

  .top-3\/5 {
    top: 60%;
  }

  .top-4\/5 {
    top: 80%;
  }

  .top-1\/6 {
    top: 16.666%;
  }

  .top-5\/6 {
    top: 83.333%;
  }

  .top-1\/8 {
    top: 12.5%;
  }

  .top-3\/8 {
    top: 37.5%;
  }

  .top-5\/8 {
    top: 62.5%;
  }

  .top-7\/8 {
    top: 87.5%;
  }

  .top-1\/12 {
    top: 8.3%;
  }

  .top-5\/12 {
    top: 41.66%;
  }

  .top-7\/12 {
    top: 58.33%;
  }

  .top-9\/12 {
    top: 75%;
  }

  .top-11\/12 {
    top: 91.66%;
  }

  ul {
    @apply prose list-none;
  }

  .w-13 {
    width: 3.25rem;
  }

  .w-1\/5 {
    width: 20%;
  }

  .w-2\/5 {
    width: 40%;
  }

  .w-3\/5 {
    width: 60%;
  }

  .w-4\/5 {
    width: 80%;
  }

  .w-1\/7 {
    width: 14.28%;
  }

  .w-2\/7 {
    width: 28.57;
  }

  .w-3\/7 {
    width: 42.86%;
  }

  .w-4\/7 {
    width: 57.14%;
  }

  .w-5\/7 {
    width: 71.43;
  }

  .w-6\/7 {
    width: 85.71%;
  }

  .w-1\/8 {
    width: 12.5%;
  }

  .w-3\/8 {
    width: 37.5%;
  }

  .w-5\/8 {
    width: 62.5%;
  }

  .w-7\/8 {
    width: 87.5%;
  }
}
