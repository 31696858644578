@tailwind components;

@layer components {

  /* .friendly {
        -webkit-box-shadow: rgba(45, 255, 196, 0.83);
        -moz-box-shadow: rgba(45, 255, 196, 0.83);
        box-shadow: rgba(45, 255, 196, 0.83);
    }

    .enemy {
        -webkit-box-shadow: rgba(255, 46, 46, 0.83);
        -moz-box-shadow: rgba(255, 46, 46, 0.83);
        box-shadow: rgba(255, 46, 46, 0.83);
    }

    .negotiation {
        -webkit-box-shadow: rgba(0, 94, 255, 0.83);
        -moz-box-shadow: rgba(0, 94, 255, 0.83);
        box-shadow: rgba(0, 94, 255, 0.83);
    }

    .unknown {
        -webkit-box-shadow: rgba(199, 198, 209, 0.83);
        -moz-box-shadow: rgba(199, 198, 209, 0.83);
        box-shadow: rgba(199, 198, 209, 0.83);
    } */
  .friendly {
    @apply text-green-400;
  }

  .enemy {
    @apply text-red-400;
  }

  .negotiation {
    @apply text-blue-400;
  }

  .unknown {
    @apply text-gray-400;
  }

  .tech-low {
    -webkit-box-shadow: 0px 0px 19px 9px;
    -moz-box-shadow: 0px 0px 19px 9px;
    box-shadow: 0px 0px 19px 9px;
  }

  .tech-high {
    -webkit-box-shadow: 0px 0px 19px 16px;
    -moz-box-shadow: 0px 0px 19px 16px;
    box-shadow: 0px 0px 19px 16px;
  }
}
