@tailwind components;

@layer components {
  .edit-dialog {
    background: #fff;
    border-radius: 8px;
    /* padding: 8px; */
    overflow-y: scroll;
  }

  .cdk-focus-trap-anchor {
  }

  .cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    left: 0;
  }

  [dir="rtl"] .cdk-visually-hidden {
    left: auto;
    right: 0;
  }

  .cdk-overlay-container,
  .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .cdk-overlay-container {
    position: fixed;
    z-index: 1000;
  }

  .cdk-overlay-container:empty {
    display: none;
  }

  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
  }

  .cdk-overlay-pane {
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }

  .cdk-overlay-backdrop {
    position: absolute;
    inset: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
  }

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
  }

  .cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }

  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.32);
  }

  .cdk-overlay-transparent-backdrop {
    transition: visibility 1ms linear, opacity 1ms linear;
    visibility: hidden;
    opacity: 1;
  }

  .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
    visibility: visible;
  }

  .cdk-overlay-backdrop-noop-animation {
    transition: none;
  }

  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
  }

  .cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll;
  }

  .cdk-dialog-container {
    display: block;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  }
}
