@tailwind screens;

/* Min-Width: 200px */
@screen iBTTiny {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets200_blk40.png");
  }

  td {
    @apply iBTTiny:align-top;
  }
}

/* Min-Width: 250px */
@screen iBTeeny {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets250_blk40.png");
  }
}

/* Min-Width: 300px */
@screen ittyBitty {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets300_blk40.png");
  }
}

/* Min-Width: 320px */
@screen pIPHONEse {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/pIPHONEse_blk40.png");
  }

  .modal-title {
    @apply p-2 text-white text-1.125;
  }
}

/* Min-Width: 360px */
@screen pAndroid {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/pAndroid_blk40.png");
  }
}

/* Min-Width: 375px */
@screen pIPHONE8 {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/pIPHONE8_blk40.png");
  }
}

/* Min-Width: 414px */
@screen pIPHONE8p {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets450_blk40.png");
  }
}

/* Min-Width: 640px */
@screen sm {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets720_blk40.png");
  }
}

/* Min-Width: 720px */
@screen lanPixel2xl {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets1024_blk40.png");
  }

  .modal-title {
    @apply p-2 text-white text-1.25 whitespace-nowrap;
  }

  td {
    @apply iBTTiny:align-middle;
  }
}

/* Min-Width: 1024px */
@screen md {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets1235_blk40.png");
  }

  .modal-title {
    @apply p-2 text-white text-1.5 whitespace-nowrap;
  }
}

/* Min-Width: 1280px */
/* Casio Projector XJ-V110W
RGB Signal Native Resolution
See User's Guide page: En-15 */
@screen lg {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets2088_blk40.png");
  }
}

/* Min-Width: 1440px */
/* Harrington MacBook Default Resolution */
/* Janeway MacBook Preferred Resolution */
@screen xl {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets2088_blk40.png");
  }
}

/* Min-Width: 1680px */
/* Janway MacBook Default Resolution */
@screen 2xl {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets2088_blk40.png");
  }
}

/* Min-Width: 1920px */
/* Casio Projector XJ-V110W
HDMI Signal // Max Res 1920 x 1200 (WUXGA)
Rizzoli Game Laptop - Extended screen - Manually
switch video to 1920 x 1200 */
@screen 3xl {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets2088_blk40.png");
  }
}

/* Max-Width: 4800px */
@screen 4xl {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets4800_blk40.png");
  }
}

/* Max-Width: 4800px */
@screen 5xl {
  .bg-wallpaper-img {
    background-image: url("/assets/_backgrounds/bgPlanets4800_blk40.png");
  }
}
