@tailwind components;

@layer components {

  /* * {
        @apply box-border;
      } */
  .tabSection {
    @apply relative z-0 w-full flex text-1.25 items-center;
  }

  .tabSection:after {
    @apply py-10 w-full text-white text-center text-1.875 top-10 left-0 absolute;
  }

  .tabButton {
    @apply w-full h-16 font-bold text-center block bg-gray-100 border border-white border-b-2 cursor-pointer text-1.5 text-yellow-300 bg-opacity-15 whitespace-nowrap rounded-t-xl;
  }

  .tabRow:focus .tabButton,
  .tabButton:focus,
  .tabButtonSelected {
    @apply outline-none text-accent-lime-500 border-lime-400;
  }

  .tabContent {
    @apply p-4 w-full text-center text-white flex flex-col bg-gray-100 border outline-none border-white bg-opacity-15 text-1.25;
  }

  .tabRow {
    @apply mt-4 w-1/3 inline-flex;
  }

  .tabRow:focus {
    @apply outline-none;
  }
}
