@tailwind components;

/* POPOVER is Deprecated - Instead use Tooltip */
@layer components {
  .popoverStyle {
    @apply absolute p-2 z-50 text-1.25 text-black bg-white border-black border-solid border-1;
  }

  .popoverHover {
    @apply relative;
  }
}

/* //ANCHOR Top-Right - tr */
@layer components {
  .tooltip-l {
    @apply relative;
  }

  /* //: centent is the magic */
  .tooltip-l:before {
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    bottom: 55%;
    transform: translateY(-50%);
    /* // move to right */
    left: 40%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-l:hover:before {
    @apply block;
  }

  .tooltip-r {
    @apply relative;
  }

  /* //: centent is the magic */
  .tooltip-r:before {
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    bottom: 10%;
    transform: translateY(-50%);
    /* // move to right */
    left: 90%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-r:hover:before {
    @apply block;
  }

  .tooltip-tr {
    @apply relative;
  }

  /* //: centent is the magic */
  .tooltip-tr:before {
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    bottom: 55%;
    transform: translateY(-50%);
    /* // move to right */
    left: 40%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-tr:hover:before {
    @apply block;
  }

  .tooltip-tr-black {
    @apply relative;
  }

  .tooltip-tr-black:before {
    /* //  centent is the magic */
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    bottom: 42%;
    transform: translateY(-50%);
    /* // move to right */
    left: 45%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-tr-black:hover:before {
    @apply block;
  }
}

/* //ANCHOR Bottom-Right - br */
@layer components {
  .tooltip-br {
    @apply relative;
  }

  .tooltip-br:before {
    /* //  content is the magic */
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    top: 134%;
    transform: translateY(-50%);
    /* // move to right */
    left: 16%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: 200px;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-br:hover:before {
    @apply block;
  }
}

/* //ANCHOR Top-Left - tl */
@layer components {
  .tooltip-tl {
    @apply relative;
  }

  .tooltip-tl:before {
    /*  centent is the magic */
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    bottom: 50%;
    transform: translateY(-50%);
    /* // move to left */
    right: -9%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-tl:hover:before {
    @apply block;
  }
}

/* //ANCHOR Bottom-left - bl */
@layer components {
  .tooltip-bl {
    @apply relative;
  }

  .tooltip-bl:before {
    /* //  content the magic */
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    top: 90%;
    transform: translateY(-50%);
    /* // move to right */
    right: 51%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-bl:hover:before {
    @apply block;
  }
}

/*  TOOLTIP - TOP bg-white */
@layer components {
  .tooltip-t {
    @apply relative;
  }

  .tooltip-t:before {
    /* Here is the magic */
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    bottom: 90%;
    transform: translateY(-50%);
    /* // move to right */
    left: 0%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    tooltip-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-t:after {
    content: "";
    position: absolute;
    /* position tooltip correctly */
    left: 50%;
    right: 50%;
    margin-left: -10px;
    /* vertically center */
    top: -15%;
    transform: translateY(-50%);
    /* the arrow */
    border: 10px solid #fff;
    border-color: white transparent transparent transparent;
    display: none;
  }

  .tooltip-t:hover:before,
  .tooltip-t:hover:after {
    @apply block;
  }
}

/*  TOOLTIP - BOTTOM bg-white */
@layer components {
  .tooltip-b {
    @apply relative;
  }

  .tooltip-b:before {
    /* Here is the magic */
    content: attr(data-tooltip);
    position: absolute;
    /* // vertically center */
    top: 90%;
    transform: translateY(-50%);
    /* // move to right */
    left: -155%;
    /* // add a small left margin */
    margin-left: 4px;
    margin-top: 4px;
    /* // basic styles */
    width: auto;
    padding: 4px;
    border-radius: 2px;
    background: #fff;
    color: #000;
    tooltip-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    white-space: nowrap;
    z-index: 50;
    /* // hide by default */
    display: none;
  }

  .tooltip-b:after {
    content: "";
    position: absolute;
    /* position tooltip correctly */
    left: 50%;
    margin-left: -10px;
    /* vertically center */
    bottom: -15%;
    transform: translateY(-50%);
    /* the arrow */
    border: 10px solid #fff;
    border-color: white transparent transparent transparent;
    display: none;
  }

  .tooltip-b:hover:before,
  .tooltip-b:hover:after {
    @apply block;
  }
}
