@tailwind components;

@layer components {
  .navbar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    background-image: linear-gradient(-180deg,
        #060061 0%,
        #0b0479 2%,
        #06025d 100%,
        #222 100%);
    border-color: #000;
  }

  .navbarLeft {
    background: linear-gradient(90deg,
        #060061 0%,
        #0b0479 2%,
        #06025d 100%,
        #222 100%);
    border-color: #000;
  }

  .navbarLeft8 {
    background: linear-gradient(90deg,
        #060061 0%,
        #0b0479 2%,
        #06025d 100%,
        #222 100%);
    border-color: #000;
  }

  .navbarTop {
    background: linear-gradient(-180deg,
        #060061 0%,
        #0b0479 2%,
        #06025d 100%,
        #222 100%);
    border-color: #000;
  }
}
