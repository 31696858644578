@tailwind components;

@layer components {

  /* example-list */
  .dropContainer {
    @apply w-full;
  }

  /* example-box */
  .dragRowItem {
    @apply w-full flex items-center justify-between box-border;
  }

  /* example-box:active */
  .dragRowItem:active {
    @apply shadow transition;
  }

  .dragRowItem:last-child {
    border: none;
  }

  .rowDragHandle {
    @apply p-1 top-0 left-0 relative text-red-900 cursor-move;
  }

  .dropContainer.cdk-drop-list-dragging .dragRowItem:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .dragFieldItem {
    @apply w-full flex items-center justify-between box-border;
  }

  .dragFieldItem:active {
    @apply shadow transition;
  }

  .dragFieldItem:last-child {
    border: none;
  }

  .fieldDragHandle {
    @apply p-1 top-0 right-0 relative text-green-900 cursor-move;
  }

  .dropContainer.cdk-drop-list-dragging .dragFieldItem:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drag-preview {
    @apply bg-gray-600 rounded shadow-md bg-opacity-30 border-box;
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .slide-out {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.5s, opacity 0.5s;
  }

  .drawer:hover+.slide-out {
    opacity: 1;
    transform: translateX(0%);
  }

  .drawer:active+.slide-out {
    opacity: 1;
    transform: translateX(0%);
  }

  .showEmptyRows {
    @apply border border-red-800 border-dashed flex;
  }

  .noShowEmptyRows {
    @apply border-none;
  }
}
