@tailwind components;

@layer components {
  .amkYellow-dk {
    @apply border-collapse border-yellow-900 border-none bg-yellow-900;
  }

  .amkBlue-dk {
    @apply border-collapse border-blue-900 border-none bg-blue-900;
  }

  .amkPurple-dk {
    @apply border-collapse border-purple-900 border-none bg-purple-900;
  }

  .amkGreen-dk {
    @apply border-collapse border-green-900 border-none bg-green-900;
  }

  .amkRed-dk {
    @apply border-collapse border-red-900 border-none bg-red-900;
  }

  .amkTeal-dk {
    @apply border-collapse border-teal-900 border-none bg-teal-900;
  }

  .amkPink-dk {
    @apply border-collapse border-pink-900 border-none bg-pink-900;
  }

  .amkYellow-lte {
    @apply border-collapse border-yellow-900 border-none bg-yellow-200;
  }

  .amkBlue-lte {
    @apply border-collapse border-blue-900 border-none bg-blue-200;
  }

  .amkPurple-lte {
    @apply border-collapse border-purple-900 border-none bg-purple-200;
  }

  .amkGreen-lte {
    @apply border-collapse border-green-900 border-none bg-green-200;
  }

  .amkRed-lte {
    @apply border-collapse border-red-900 border-none bg-red-200;
  }

  .amkTeal-lte {
    @apply border-collapse border-teal-900 border-none bg-teal-200;
  }

  .amkPink-lte {
    @apply border-collapse border-pink-900 border-none bg-pink-200;
  }

  ;
}
