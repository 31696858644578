@tailwind components;

@layer components {
  .modal {
    @apply top-0 right-0 bottom-0 left-0 fixed hidden overflow-hidden outline-0;
  }

  .modal.fade .modal-dialog {
    transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
  }

  .modal.in .modal-dialog {
    transform: translate(0, 0);
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  /* Shell div to position the modal with bottom padding */
  .modal-dialog {
    @apply my-10 w-auto relative iBTTiny:w-11/12 lanPixel2xl:w-3/5;
  }

  /* // Modal background */
  .modal-backdrop {
    @apply top-0 right-0 bottom-0 left-0 fixed bg-black;
  }

  .modal-backdrop.fade {
    opacity: 0;
  }

  .modal-backdrop.in,
  .modal-backdrop.fade.in {
    opacity: 0.5;
  }

  .modal-body {
    @apply relative w-full p-0 text-1.25 text-gray-900 bg-white border overflow-hidden;
  }

  .modal-center {
    @apply px-1 text-gray-900 bg-white;
  }

  .modal-footer {
    @apply p-4 text-white bg-accent-blue-dk-500;
    border-radius: 0 0 8px 8px;
  }

  .modal-header {
    @apply p-4 text-white bg-accent-blue-dk-500;
    border-radius: 8px 8px 0 0;
  }

  .modal-title {
    @apply p-2 text-white text-1.75;
  }

  .fade {
    opacity: 0;
    transition: opacity 0.15s linear;
  }

  .fade.in {
    opacity: 1;
  }
}
